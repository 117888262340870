import React, { useEffect } from "react"
import { navigate, Link } from "gatsby"
import { isEDMode, getNested, getLocalizedURL } from '@src/helper';

// Style Imports
// import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"

const ResubscribeConfirmed = ({location}) => {

    if (isEDMode) {
        location.state = {
            values: {
                email: '[name@domain.com]',
                unsubscribeAuriniaAlliance: true,
                unsubscribeDiseaseAwareness: true,
                unsubscribeCorporate: true
            }
        }
    }

    useEffect(()=>{
        //gatsby isn't mapping location.state correctly on build so we need to do a nested check to see if email exists if not we redirect
        if(!isEDMode && !getNested(location,'state', 'values', 'email')) {
            navigate( getLocalizedURL('registration','es') );
        }
    },[])


    return (
        <Layout addedClass="page--resubscribe-confirmed">
            <Seo 
            title="Gracias por volver a inscribirse | LUPKYNIS&reg; (voclosporina)" 
            description="" 
            robots="noindex" 
            canonicalURL={"https://www.lupkynis.com" + getLocalizedURL('resubscribe-confirmed','es')} />
            <Hero addedClass="bg-light-green hero--string">
                <div className="col-wrapper">
                    <div className="container">
                        <div className="hero__content">
                            <h1 className="heading heading--accent">¡Le damos la bienvenida de nuevo!</h1>
                            <h2>Nos complace volver a verlo</h2>
                            <p>{getNested(location,'state', 'values', 'email')}, usted ha vuelto a suscribirse con éxito a lo siguiente:</p>
                            {(getNested(location,'state', 'values', 'unsubscribeAuriniaAlliance') || getNested(location,'state', 'values', 'unsubscribeDiseaseAwareness') || getNested(location,'state', 'values', 'unsubscribeCorporate')) && <p>También ha elegido volver a suscribirse a lo siguiente:</p>}
                            <ul>
                                {getNested(location,'state', 'values', 'unsubscribeAuriniaAlliance') &&
                                    <li>Comunicaciones de comercialización sobre el apoyo al paciente de  Aurinia Alliance<sup>&reg;</sup></li>
                                }
                                {getNested(location,'state', 'values', 'unsubscribeDiseaseAwareness') &&
                                    <li>Información de concientización sobre el estado de la enfermedad</li>
                                }
                                {getNested(location,'state', 'values', 'unsubscribeCorporate') &&
                                    <li>Comunicaciones corporativas de Aurinia, los fabricantes de LUPKYNIS</li>
                                }
                            </ul>
                            <Link to={getLocalizedURL('home','es')} className="button">Regresar al inicio</Link>
                        </div>
                        <div className="hero__image">
                            <Image data={{desktopSrc: 'registration/hero.png', mobileSrc: 'registration/hero--mobile.png'}} />
                        </div>
                    </div>
                </div>
            </Hero>
        </Layout>
    )
}

export default ResubscribeConfirmed